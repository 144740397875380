<template>
  <div class="tag-box">
    <div class="inner">
      <slot />
    </div>
    <img src="../assets/tonghong/tag_left.png" class="tag-bg tag-left">
    <img src="../assets/tonghong/tag_right.png" class="tag-bg tag-right">
  </div>
</template>

<script>
export default {
  name: 'TagBox'
}
</script>

<style lang="scss" scoped>
.tag-box {
  display: inline-block;
  position: relative;
  min-width: 100px;
  height: 45px;
  padding: 0 30px 0 20px;
  & > .inner {
    min-width: 20px;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
    background: url("~@/assets/tonghong/tag_body.png") repeat-x left center;
    background-size: auto 100%;
  }
  & > .tag-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    height: 45px;
    &.tag-left {
      left: 0;
    }
    &.tag-right {
      right: 0;
    }
  }
}
</style>
