<template>
    <div style="width: 100%; height: 100%;">
        <baidu-map v-if="show" class="map" :center="center" :zoom="zoom" @ready="handler" :scroll-wheel-zoom="true" :map-click="false" :pinch-to-zoom="true" :double-click-zoom="true" map-type="BMAP_NORMAL_MAP">
            <my-overlay v-for="(item, index) in villageList" :offline='item.onLineNum' :key="index" :village="item" :position="{ lng: item.v_longitude, lat: item.v_latitude }" :text="item.v_village_name" :elevatorNum="item.elevatorNum" :villageId="item.v_village_id" :peopleTrappedCount="item.peopleTrappedCount" :electroMobileCount="item.electroMobileCount" :active="item.active" @mouseover.native="item.active = true" @mouseleave.native="item.active = false" @openMapDrawer="openMapDrawer(item)"></my-overlay>
        </baidu-map>
        <!-- div class="control" v-if="showZoomControl">
            <span class="control-btn" @click="onEnlarge"><img class="control-icon" src="@/assets/enlarge.png"></span>
            <div style="width:100%; height:1px; background-color: #ededed;;"></div>
            <span class="control-btn" @click="onNarrow">
                <img class="control-icon" src="@/assets/narrow.png">
            </span>
        </div> -->
    </div>
</template>
<script>
import MyOverlay from './MyOverlay.vue'
import { villageCount } from '@/api/home/index.js'
export default {
    name: 'TestBaiDu',
    components: {
        MyOverlay
    },
    data() {
        return {
            center: { lng: 121.48929108593748, lat: 31.239161773464367 },
            zoom: 10,
            mapDrawer: false,
            villageList: [],
            mapDrawerTitle: '',
            villageElevatorList: [],
            elevatorPosition: {},
            BMap: '',
            map: '',
            bmInfoWindow: false,
            v_village_id: '',
            // 监控
            MONITORModalFlag: false,
            elevatorCode: '',
            elevatorId: '',
            monitor_flag: true,
            show: false,
            villageCountData: {
                overdue: '',
                pageIndex: '',
                pageSize: '',
                eType: '',
                timeFlag: '00'
            },
            showZoomControl: false,
        }
    },
    created() {
        this.searchVillageMap()
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        },
        user() {
            return this.$store.state.user
        },
    },
    methods: {
        test() {
            console.log(334);
        },
        handler({ BMap, map }) {
            this.BMap = BMap
            this.map = map
            if (this.theme == 'dark')
                this.map.setMapStyleV2({
                    styleId: '2f143edcc9ba35e22eec789b33d8eecc'
                })
            else
                this.map.setMapStyleV2({
                    styleId: 'e3aff207b5f958bcd4da897ea4230104'
                })
            this.locateIfNeeded()
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        },
        searchVillageMap(msg) {
            if (msg) {
                this.villageCountData.timeFlag = msg
            }
            villageCount(this.buildParams(this.villageCountData)).then(res => {
                if (res.code === '0000') {
                    this.show = true
                    let array1 = res.info.filter((item) => {
                        return item.elevatorNum == 0
                    })
                    let array2 = res.info.filter((item) => {
                        return item.elevatorNum > 0
                    })

                    this.villageList = array1.concat(array2)

                    this.villageList.forEach(item => {
                        item['active'] = false
                    })
                    this.locateIfNeeded()
                }
            })
        },
        locateIfNeeded() {
            if (this.map) {
                let point = new BMap.Point(121.704899, 31.397188)
                if (this.villageList.length) {
                    let array = this.villageList.filter((item) => {
                        return item.elevatorNum > 0 && item.onLineNum > 0
                    })
                    if (array.length)
                        point = new BMap.Point(array[0].v_longitude, array[0].v_latitude)
                }
                this.map.centerAndZoom(point, 14)
                // this.showZoomControl = true
                var navigationControl = new BMap.NavigationControl({
                    // 靠左上角位置
                    anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
                    // LARGE类型
                    type: BMAP_NAVIGATION_CONTROL_ZOOM,
                    // 启用显示定位
                    //enableGeolocation: true
                });
                this.map.addControl(navigationControl);
            }
        },
        openMapDrawer(data) {
            console.log(data, '3454444');
            this.$emit('openMapDrawer2', data)
        },

        onEnlarge() {

        },

        onNarrow() {

        }

    },
    watch: {
        theme(newVal, oldVal) {
            if (newVal != oldVal) {
                if (this.map) {
                    if (newVal == 'dark')
                        this.map.setMapStyleV2({
                            styleId: '2f143edcc9ba35e22eec789b33d8eecc'
                        })
                    else
                        this.map.setMapStyleV2({
                            styleId: 'e3aff207b5f958bcd4da897ea4230104'
                        })
                }

            }
        }
    }
}
</script>
<style lang="less" scoped>
.map {
    width: 100%;
    height: 100%;
    position: relative;
}

.footer_info {
    height: 100%;
    overflow: auto;
}

.footer_info::-webkit-scrollbar {
    display: none;
}

.control {
    position: absolute;
    bottom: 70px;
    right: 30px;
    width: 44px;
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .08);
    border-radius: 4px;
}

.control-icon {
    width: 12px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    cursor: pointer;
}

.control-btn {
    width: 44px;
    height: 28px;
    display: block;
    cursor: pointer;
    text-align: center;
    margin: 16px 0
}
</style>