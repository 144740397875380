<template>
  <div class="home">
    <div class="home-left">
      <div v-if="rescueInfo" class="sos">
        <Icon style="font-size: 26px; color: red" type="ios-flash" />
        <div class="text">紧急救援</div>
        <div class="scool">
          <div
            class="blink"
            @click="
              () => {
                $router.push({
                  path: '/monitor/single',
                  query: {
                    eventid: rescueInfo.event_id,
                    elevatorCode: rescueInfo.v_elevator_code,
                  },
                })
              }
            "
          >
            {{ rescueInfo.v_elevator_code }}
          </div>
        </div>
      </div>
      <div class="table">
        <border-box id="box4">
          <div class="fault">
            <div class="tips">
              <div class="round">
                <div class="text">
                  <div>故障</div>
                  <div>提示</div>
                </div>
              </div>
            </div>
            <div class="list">
              <el-table
                ref="singleTable"
                :data="elevatorHlsInfoFault"
                highlight-current-row
                style="width: 100%; height: 100%"
              >
                <el-table-column
                  property="dt_report_time"
                  width="180px"
                  label="上报日期"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  property="v_fault_name"
                  label="故障类型"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  width="210"
                  property="v_address"
                  label="安装地址"
                  align="center"
                >
                </el-table-column>
                <el-table-column label="操作" width="90" align="center">
                  <template slot-scope="scope">
                    <el-button
                      @click="deleteRow(scope.row.v_fault_id)"
                      type="primary"
                      size="mini"
                    >
                      回看
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </border-box>
        <border-box id="box4">
          <div class="fault">
            <div class="tips">
              <div class="round" style="background: orange">
                <div class="text">
                  <div>不文明</div>
                  <div>行为</div>
                </div>
              </div>
            </div>
            <div class="list">
              <el-table
                ref="singleTable"
                :data="elevatorHlsInfoUncivilized"
                highlight-current-row
                style="width: 100%; height: 100%"
              >
                <el-table-column
                  property="dt_report_time"
                  width="180px"
                  label="上报日期"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  property="v_fault_name"
                  label="不文明行为类型"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  property="v_address"
                  label="安装地址"
                  align="center"
                >
                </el-table-column>
                <el-table-column label="操作" width="90" align="center">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="deleteRow(scope.row.v_fault_id)"
                      type="primary"
                      size="mini"
                    >
                      回看
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </border-box>
      </div>
      <div class="vallage-list">
        <div class="nav">
          <div class="state">
            <div
              @click="changeState(0)"
              :class="stateType == '0' ? 'active type' : 'type'"
            >
              小区模式
            </div>
            <div
              @click="changeState(1)"
              :class="stateType == '1' ? 'active type' : 'type'"
            >
              地图模式
            </div>
            <div
              v-if="stateType == '2'"
              :class="stateType == '2' ? 'active type' : 'type'"
            >
              {{ villageName }}
            </div>
          </div>
          <div class="search">
            <div class="time-select">
              <p
                @click="changeTime('00')"
                class="time week"
                :class="timeType == '00' ? 'active' : ''"
              >
                周
              </p>
              <p
                @click="changeTime('11')"
                class="time mouth"
                :class="timeType == '11' ? 'active' : ''"
              >
                月
              </p>
              <p
                @click="changeTime('22')"
                class="time year"
                :class="timeType == '22' ? 'active' : ''"
              >
                年
              </p>
            </div>
          </div>
        </div>
        <!-- 小区模式 -->
        <div v-if="stateType == 0" class="vallage-content">
          <div
            :class="item.onLineNum == 0 ? 'item offline' : 'item'"
            v-for="(item, index) in vallageList"
            :key="index"
            @click="changeVillage(item.v_village_id, item.v_village_name)"
          >
            <div class="item-title">
              <div class="vallage-name">{{ item.v_village_name }}</div>
              <div class="ele-num">{{ item.elevatorNum }}</div>
            </div>
            <div class="ele-state">
              <div class="state">
                <div class="state-num">{{ item.peopleTrappedCount }}</div>
                <div class="state-type">
                  困人
                  <Icon type="md-man" style="color: #d1217f; font-size: 16px" />
                </div>
              </div>
              <div class="state">
                <div class="state-num">{{ item.repairOrder }}</div>
                <div class="state-type">
                  急修
                  <Icon
                    type="ios-construct"
                    style="color: #ef4444; font-size: 16px"
                  />
                </div>
              </div>
              <div class="state">
                <div class="state-num">
                  {{ item.blockDoorCount ? item.blockDoorCount : 0 }}
                </div>
                <div class="state-type">
                  关门阻挡
                  <Icon
                    type="ios-close-circle"
                    style="color: #ffc412; font-size: 16px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="vallageList.length == 0">暂无小区</div>
        </div>
        <!-- 地图模式 -->
        <div v-if="stateType == 1" class="map">
          <Baidumap ref="Baidumap" @openMapDrawer2="openMapDrawer2" />
          <transition name="fade">
            <div
              class="shadow-wrapper"
              v-if="drowShow"
              @click="clickShadow()"
            ></div>
          </transition>
          <div v-if="drowShow" class="drow">
            <el-table
              ref="singleTable"
              :data="elevatorList"
              highlight-current-row
              style="width: 100%; height: 100%"
            >
              <el-table-column
                property="v_address"
                label="电梯编号"
                align="center"
              >
              </el-table-column>
              <el-table-column label="操作" width="90" align="center">
                <template slot-scope="scope">
                  <el-button
                    @click="
                      () => {
                        $router.push({
                          path: '/monitor/single',
                          query: {
                            villageId: scope.row.villageId,
                            registerNum: scope.row.register_number,
                            elevatorId: scope.row.v_elevator_id,
                            elevatorCode: scope.row.v_elevator_code,
                          },
                        })
                      }
                    "
                    type="primary"
                    size="mini"
                  >
                    查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 电梯列表 -->
        <div v-if="stateType == 2" class="elevator-content">
          <div class="ele-state">
            <div class="item">
              <p style="background: #22c55e"></p>
              <div class="color">正常</div>
            </div>
            <div class="item">
              <p style="background: #64748b"></p>
              <div>离线</div>
            </div>
            <div class="item">
              <p style="background: #d1217f"></p>
              <div class="color">困人</div>
            </div>
            <div class="item">
              <p style="background: #ffc412"></p>
              <div>检修</div>
            </div>
            <div class="item">
              <p style="background: #ef4444"></p>
              <div>故障</div>
            </div>
          </div>
          <div class="elevator-list">
            <div
              class="item"
              v-for="(item, index) in elevatorList"
              :key="index"
              @click="
                () => {
                  $router.push({
                    path: '/monitor/single',
                    query: {
                      villageId: item.villageId,
                      registerNum: item.register_number,
                      elevatorId: item.v_elevator_id,
                      elevatorCode: item.v_elevator_code,
                    },
                  })
                }
              "
            >
              <!-- <div class="title">正常</div> -->
              <!-- 离线 -->
              <div v-if="item.i_on_line == 0">
                <!-- <div class="title">离线</div> -->
                <img src="../../assets/off-line.png" alt="" />
              </div>
              <!-- 困人 -->
              <div v-else-if="item.i_fault_status == 2">
                <!-- <div class="title">困人</div> -->
                <img src="../../assets/Trapped.png" alt="" />
              </div>
              <!-- 检修 -->
              <div v-else-if="item.i_mode_status != 0">
                <!-- <div class="title">检修</div> -->
                <img src="../../assets/overhaul.png" alt="" />
              </div>
              <!-- 故障 -->
              <div v-else-if="item.i_fault_status == 1">
                <!-- <div class="title">故障</div> -->
                <img src="../../assets/sos.png" alt="" />
              </div>
              <!-- 正常 -->
              <div v-else>
                <!-- <div class="title">正常</div> -->
                <img src="../../assets/normal.png" alt="" />
              </div>
              <div style="font-size: 14px">{{ item.v_elevator_name }}</div>
              <div style="font-size: 12px">{{ item.v_elevator_code }}</div>
            </div>
            <div v-if="elevatorList.length == 0">暂无电梯</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-right">
      <border-box id="box1">
        <div class="num">
          <div class="flex ele-num">
            <div>
              <div class="nor-text" style="font-size: 14px">物联网电梯总数</div>
              <div
                class="nor-text"
                style="font-size: 30px; font-weight: 700; text-align: center"
              >
                {{ elevatorCount.total }}
              </div>
            </div>
          </div>
          <div class="flex ele-num">
            <div>
              <div class="nor-text" style="font-size: 14px">商场直梯台数</div>
              <div
                class="highlight-text"
                style="font-size: 30px; font-weight: 700; text-align: center"
              >
                {{
                  user.user.v_project_id == 8089373891892543488
                    ? '524'
                    : elevatorCount.total
                }}
              </div>
            </div>
          </div>
        </div>
      </border-box>
      <border-box id="box2">
        <tag-box class="pull-left">电梯分类</tag-box>
        <div class="pie pull-right" style="display: flex; gap: 12px">
          <div style="flex: 1" class="panel-body one">
            <div class="pieTop">
              <div class="echart-box" id="E_zkcl"></div>
            </div>
            <div class="content">
              <div class="item" v-for="(item, index) in chartData" :key="index">
                <div class="left">
                  <div
                    :style="{
                      width: '16px',
                      height: '16px',
                      background: item.color,
                      'border-radius': '50%',
                      'margin-right': '10px',
                    }"
                  ></div>
                  <div>{{ item.name }}</div>
                </div>
                <div class="right">{{ item.number }}</div>
              </div>
            </div>
          </div>
          <div style="flex: 1" class="panel-body one">
            <div class="pieTop">
              <div class="echart-box" id="E_zkcl2"></div>
            </div>
            <div class="content">
              <div
                class="item"
                v-for="(item, index) in chartData2"
                :key="index"
              >
                <div class="left">
                  <div
                    :style="{
                      width: '16px',
                      height: '16px',
                      background: item.color,
                      'border-radius': '50%',
                      'margin-right': '10px',
                    }"
                  ></div>
                  <div>{{ item.name }}</div>
                </div>
                <div class="right">{{ item.number }}</div>
              </div>
            </div>
          </div>
        </div>
      </border-box>
      <border-box id="box3">
        <tag-box class="pull-left">维保信息</tag-box>
        <div class="main-info pull-right">
          <div class="contemt">
            <div v-for="(item, index) in intellData" :key="index">
              <p>{{ index + 1 }}</p>
              <span style="font-weight: 700; font-size: 16px"
                >{{ item.lable }}：{{ item.number }}</span
              >
            </div>
            <div v-if="intellData.length == 0">暂无信息</div>
          </div>
        </div>
      </border-box>
    </div>
    <Modal
      footer-hide
      :mask="true"
      :mask-closable="false"
      :scrollable="true"
      v-model="modaDetail"
      width="550"
      class="model"
    >
      <div style="background: #fff">
        <Row :gutter="10">
          <Tabs size="small">
            <TabPane label="视频" class="TimeDiv">
              <video
                :src="videoUrl"
                controls
                ref="video"
                v-if="videoUrl != '' && videoUrl != null"
              >
                您的浏览器不支持video元素。
              </video>
              <br />
              <font style="margin-left: 205px" v-if="!videoUrl">暂无视频</font>
            </TabPane>
            <TabPane label="图片">
              <Scroll v-if="faultImageList.length !== 0">
                <div
                  :key="index"
                  dis-hover
                  style="margin: 32px 0"
                  v-for="(item, index) in faultImageList"
                >
                  <img :src="item" style="max-width: 100%" />
                </div>
              </Scroll>
              <br />
              <font
                style="margin-left: 210px"
                v-if="faultImageList.length === 0"
                >暂无图片</font
              >
            </TabPane>
          </Tabs>
        </Row>
      </div>
    </Modal>
    <FaultAlarm ref="faultAlarm" />
  </div>
</template>
<script>
import Baidumap from '../map/map.vue'
import FaultAlarm from '@/components/alarm/index'
import BorderBox from '../../components/BorderBox'
import TagBox from '../../components/TagBox'

import {
  getElevatorHlsInfo,
  searchFaultList,
  countElevator,
  getFaultDetail,
  intelligentSupervision,
  villageCount,
  getElevatorsStatus,
  villagesCountRate,
  personShuttingLately,
  searchUncivilizedltList,
  searchMaiXinFaultList,
  elevatorClassificationQuantityList,
} from '@/api/home/index.js'
export default {
  data() {
    return {
      chartData: [
        {
          name: '客梯',
          number: '0',
          color: '#18DE98',
        },
        {
          name: '杂物电梯',
          number: '0',
          color: '#ff6699',
        },
        {
          name: '自动扶梯',
          number: '0',
          color: '#249DD8',
        },
        {
          name: '货梯',
          number: '0',
          color: '#ecc222',
        },
      ],
      chartData2: [
        {
          name: '离线',
          number: '3',
          color: '#ff6699',
        },
        {
          name: '困人',
          number: '3',
          color: 'red',
        },
        {
          name: '检修',
          number: '5',
          color: '#00DEFF',
        },
        {
          name: '故障',
          number: '2',
          color: 'orange',
        },
        {
          name: '正常',
          number: '7',
          color: '#fff',
        },
      ],
      elevatorList: [],
      vallageList: [],
      elevatorHlsInfoFault: [],
      elevatorHlsInfoUncivilized: [],
      input2: '',
      stateType: 1,
      timeType: '00',
      select: 1,
      input: '',
      drowShow: false,
      center: { lng: 0, lat: 0 },
      zoom: 3,
      elevatorCount: '',
      classificationParams: {
        register_number: '',
        villageId: '',
        timeFlag: '',
        faultType: '',
        backType: 1,
        vElevatorCode: '',
        videoUrl: '',
        // vProjectId: '8089373891892543488',
      },
      intelligentData: {
        register_number: '',
        villageId: '',
        timeFlag: '00',
        faultType: '',
        // vProjectId: '8089373891892543488',
      },
      listQuery: {
        iUncivilizedBehaviorFlag: 1,
        villageId: '',
        buildingId: '',
        vElevatorCode: '',
        dtReportTime: this.formatDate(-30) + '',
        dtEndTime: this.formatDate(0) + ' 23:59:59',
        iFaultType: '',
        overdue: '',
        iStatus: '',
        pageIndex: 1,
        pageSize: 10,
        // vProjectId: '8089373891892543488',
      },
      faultListQuery: {
        iUncivilizedBehaviorFlag: 0,
        villageId: '',
        buildingId: '',
        vElevatorCode: '',
        dtReportTime: this.formatDate(-30) + '',
        dtEndTime: this.formatDate(0) + ' 23:59:59',
        overdue: '',
        pageIndex: 1,
        pageSize: 3,
        // vProjectId: '8089373891892543488',
      },
      villageCountData: {
        overdue: '',
        pageIndex: '',
        pageSize: '',
        eType: '',
        timeFlag: '00',
        // vProjectId: '8089373891892543488',
      },
      videoUrl: '',
      modaDetail: false,
      rescueInfo: null,
      HistoryRecordData: {
        vElevatorCode: '',
        timeFlag: '11',
      },
      SupervisionData: {
        iModeStatus: 2,
        vProjectId: '',
      },
      Supervision: '',
      intellData: [
        {
          lable: '昨日维保电梯数量(台)',
          number: '',
        },
        {
          lable: '本月需年检电梯数量(台)',
          number: '',
        },
        {
          lable: '逾期维保电梯',
          number: '',
        },
        {
          lable: '年检逾期电梯',
          number: '',
        },
      ],
      villageName: '',
      elevatorData: { villageId: '', vProjectId: '' },
      elevatorState: [
        {
          name: '电梯困人数',
          ratename: '电梯困人率',
          count: '',
          rate: '',
          color: '#d1217f',
          unit: '‰',
        },
        {
          name: '按期保养数',
          ratename: '按期保养率',
          count: '',
          rate: '',
          color: '#007be5',
          unit: '%',
        },
        {
          name: '故障急修数',
          ratename: '故障急修率',
          count: '',
          rate: '',
          color: '#ef4444',
          unit: '‰',
        },
        {
          name: '关门阻挡数',
          ratename: '关门阻挡率',
          count: '',
          rate: '',
          color: '#f59e0b',
          unit: '%',
        },
      ],
      faultImageList: [],

      autoRefeshTimer: null,
      option: {
        series: [
          {
            type: 'pie',
            radius: ['20%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            roseType: 'radius',
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                fontSize: 40,
                fontWeight: 'bold',
              },
            },

            data: [
              { value: 2, name: 'Search Engine' },
              { value: 0, name: 'Direct' },
              { value: 0, name: 'Email' },
            ],
          },
        ],
      },
      option2: {
        series: [
          {
            type: 'pie',
            radius: ['20%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            roseType: 'radius',
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                fontSize: 40,
                fontWeight: 'bold',
              },
            },

            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 75, name: 'Direct' },
            ],
          },
        ],
      },
      zoom: 1,
      eleQuantity: {
        register_number: '',
        villageId: '',
        timeFlag: '00',
        faultType: '',
        vProjectId: '8333958929244291072',
      },
    }
  },
  created() {
    this.initializePage()
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  components: {
    Baidumap,
    BorderBox,
    TagBox,
    FaultAlarm,
  },
  mounted() {
    if (this.autoRefeshTimer) {
      clearInterval(this.autoRefeshTimer)
      this.autoRefeshTimer = null
    }
    this.autoRefeshTimer = setInterval(() => {
      this.initializePage()
    }, 600000)

    let myChart = this.$echarts.init(document.getElementById('E_zkcl'))
    // let myChart2 = this.$echarts.init(document.getElementById('E_zkcl2'))
    myChart.setOption(this.option)
    // myChart2.setOption(this.option2)
    this.$refs.faultAlarm.startMonitor()
  },
  destroyed() {
    if (this.autoRefeshTimer) {
      clearInterval(this.autoRefeshTimer)
      this.autoRefeshTimer = null
    }
  },
  methods: {
    elevatorClassificationQuantityList() {
      elevatorClassificationQuantityList(this.eleQuantity).then((res) => {
        if (res.info.length > 0) {
          let color = ['#ff6699', '#18DE98', '#249DD8', '#ecc222']
          //   this.chartData = res.info.map(function (item, index) {
          //     return {
          //       name: item.v_name_zh_cn,
          //       number: item.number,
          //       color: color[index],
          //     }
          //   })

          res.info.forEach((item) => {
            if (item.v_name_zh_cn == '客梯') {
              this.chartData[0].number = item.number
              this.chartData[0].name = item.v_name_zh_cn
            }
            if (item.v_name_zh_cn == '杂物电梯') {
              this.chartData[1].number = item.number
              this.chartData[1].name = item.v_name_zh_cn
            }
            if (item.v_name_zh_cn == '自动扶梯') {
              this.chartData[2].number = item.number
              this.chartData[2].name = item.v_name_zh_cn
            }
            if (item.v_name_zh_cn == '货梯') {
              this.chartData[3].number = item.number
              this.chartData[3].name = item.v_name_zh_cn
            }
          })

          this.option.series[0].data = res.info.map(function (item, index) {
            return {
              name: item.v_name_zh_cn,
              value: item.number,
            }
          })
        }

        let myChart = this.$echarts.init(document.getElementById('E_zkcl'))
        myChart.setOption(this.option)
      })
    },

    initializePage() {
      this.serachHomeFaultList()
      this.searchHomeUncivilizedFaultList()
      this.countElevator()
      this.Emergencyrescue()
      this.intelligentSupervision()
      this.villageCount()
      this.villagesCountRate()
      this.elevatorClassificationQuantityList()
      this.getElevatorsStatus2()
    },
    getElevatorsStatus2() {
      getElevatorsStatus({
        villageId: '',
        vProjectId: '8333958929244291072',
      }).then((res) => {
        const data = [
          { name: '离线', number: 0, color: '#f69' },
          { name: '困人', number: 0, color: 'red' },
          {
            name: '检修',
            number: 0,
            color: '#00FFFF',
          },
          {
            name: '故障',
            number: 0,
            color: 'orange',
          },
          { name: '正常', number: 0, color: '#fff' },
        ]
        res.info.forEach((item) => {
          if (item.i_on_line == 0) {
            data[0].number++
          } else if (item.i_fault_status == 2) {
            data[1].number++
          } else if (item.i_mode_status != 0) {
            data[2].number++
          } else if (item.i_mode_status == 1) {
            data[3].number++
          } else {
            data[4].number++
          }
        })
        this.chartData2 = data
        this.option2.series[0].data = data.map(function (item, index) {
          return {
            name: item.name,
            value: item.number,
          }
        })
        let myChart2 = this.$echarts.init(document.getElementById('E_zkcl2'))
        myChart2.setOption(this.option2)
      })
    },

    buildParams(params) {
      // params.vProjectId = this.user.user.v_project_id
      return params
    },

    changeState(state) {
      this.stateType = state
    },
    changeTime(state) {
      this.timeType = state
      this.villageCountData.timeFlag = state
      if (this.stateType == 1) {
        this.$refs.Baidumap.searchVillageMap(state)
      } else {
        this.villageCount()
      }
      this.villagesCountRate()
    },
    deleteRow(faultId) {
      this.modaDetail = true
      this.getFaultDetail(faultId)
    },
    //故障详情
    getFaultDetail(faultId) {
      getFaultDetail(faultId).then((res) => {
        if (res.code === '0000') {
          console.log(res)
          this.videoUrl = res.info.videoUrl
          this.faultImageList = res.info.imageList
        }
      })
    },

    // 紧急救援
    Emergencyrescue() {
      personShuttingLately(this.buildParams(this.classificationParams)).then(
        (res) => {
          this.rescueInfo = res.info
        }
      )
    },
    // 故障提示
    serachHomeFaultList() {
      // 根据wuyePlatform判度
      searchFaultList(this.buildParams(this.faultListQuery)).then((res) => {
        this.elevatorHlsInfoFault = res.info.list
        this.elevatorHlsInfoFault.forEach((item) => {
          item.dt_report_time = item.dt_report_time
        })
      })
    },
    // 不文明行为
    searchHomeUncivilizedFaultList() {
      searchUncivilizedltList(this.buildParams(this.listQuery)).then((res) => {
        this.elevatorHlsInfoUncivilized = res.info.list
        this.elevatorHlsInfoUncivilized.forEach((item) => {
          item.dt_report_time = item.dt_report_time
        })
      })
    },

    // 小区列表
    villageCount() {
      villageCount(this.buildParams(this.villageCountData)).then((res) => {
        console.log(res)
        this.vallageList = res.info.filter((item) => {
          return item.onLineNum != 0
        })
      })
    },

    changeVillage(id, name) {
      this.stateType = 2
      this.villageName = name
      this.getElevatorsStatus(id)
    },
    // 电梯列表

    getElevatorsStatus(id) {
      this.elevatorData.villageId = id
      this.elevatorList = []
      getElevatorsStatus(this.elevatorData).then((res) => {
        console.log(res, 'res')
        this.elevatorList = res.info
      })
    },

    // 电梯数量
    countElevator() {
      countElevator('undefined').then((res) => {
        this.elevatorCount = res.info
      })
    },
    // 电梯状态
    villagesCountRate() {
      villagesCountRate(this.buildParams(this.villageCountData)).then((res) => {
        this.elevatorState[0].count = res.info.peopleTrappedCount.count
        this.elevatorState[0].rate = (
          parseFloat(res.info.peopleTrappedCount.rate) * 10
        ).toFixed(2)

        this.elevatorState[1].count = res.info.totalMaintenanceCount.count
        this.elevatorState[1].rate =
          Math.floor((res.info.totalMaintenanceCount.rate - 0) * 10) / 10

        this.elevatorState[2].count =
          res.info.faultOrderByConfirmOrCompleted.count
        this.elevatorState[2].rate = (
          parseFloat(res.info.faultOrderByConfirmOrCompleted.rate) * 10
        ).toFixed(2)

        this.elevatorState[3].count = res.info.blockDoorCount
          ? res.info.blockDoorCount.count
          : 0
        this.elevatorState[3].rate =
          Math.floor((res.info.blockDoorCount.rate - 0) * 10) / 10

        // if (res.info) {
        //   res.info.forEach(item => {
        //     if (item.key == '困人数') {
        //       this.elevatorState[0].count = item.count
        //       this.elevatorState[0].rate = item.rate
        //     }
        //     if (item.key == '保养数') {
        //       this.elevatorState[1].count = item.count
        //       this.elevatorState[1].rate = item.rate
        //     }
        //     if (item.key == '急修数') {
        //       this.elevatorState[2].count = item.count
        //       this.elevatorState[2].rate = item.rate
        //     }
        //     if (item.key == '电瓶车入梯数') {
        //       this.elevatorState[3].count = item.count
        //       this.elevatorState[3].rate = item.rate
        //     }
        //   })
        // }
      })
    },
    // 维保信息
    intelligentSupervision() {
      intelligentSupervision(this.buildParams(this.intelligentData)).then(
        (res) => {
          console.log('intelligentSupervision', res)

          this.intellData.forEach((item, index) => {
            res.info.forEach((k, j) => {
              // console.log(this.intellData[index].lable, k.label)
              if (this.intellData[index].lable == k.label) {
                console.log('k.number', k.number)
                this.intellData[index].number = k.number
              }
            })
          })
        }
      )
    },

    // 时间转化
    rTime(date) {
      var json_date = new Date(date).toJSON()
      var a = new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, ' ')
        .replace(/\.[\d]{3}Z/, '')
      return a
        .substring(a.indexOf('-') + 1)
        .substring(0, a.substring(a.indexOf('-') + 1).lastIndexOf(':'))
    },
    perFormat(percentage) {
      return percentage < 0.1 ? `${percentage}‰` : `${percentage}%`
    },
    openMapDrawer2(data) {
      this.stateType = 2
      this.villageName = data.v_village_name
      this.getElevatorsStatus(data.v_village_id)
    },
    clickShadow() {
      this.drowShow = false
    },
  },
  watch: {
    modaDetail: function (newObj) {
      if (!newObj) {
        this.videoUrl = ''
        this.faultImageList = []
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/common/styles/handle.scss';

.pie {
  .pieTop {
    height: 100px;
    display: flex;
    justify-content: center;
    // background-color: pink;
  }

  .content {
    .item {
      height: 35px;
      display: flex;
      padding-right: 10px;
      font-size: 12px;
      justify-content: space-between;
      align-items: center;
      background-color: #0c245f;
      border: 1px solid #034e90;
      margin-bottom: 8px;

      .left {
        display: flex;
        height: 100%;
        align-items: center;
        color: #00deff;
        padding: 0 10px;
        background-color: #0b2a6f;
      }
    }
  }
}

::v-deep input[type='text'],
input[type='number'] {
  border-radius: 8px;
  height: 38px;
  margin-top: 4px;
}

::v-deep .el-input {
  width: 150px;
  margin-right: 6px;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none;
}

.el-table ::v-deep th.el-table__cell > .cell {
  @include font_color('tableHeaderTextColor');
}

.el-table {
  ::v-deep .el-table__cell {
    padding: 8px 0;
    @include font_color('tableCellTextColor');
    @include background_color('tableCellBgColor');
  }
}

.el-table {
  ::v-deep .el-table__empty-block {
    @include background_color('tableEmptyBgColor');
  }
}

.el-table {
  ::v-deep th.el-table__cell.is-leaf {
    @include border_color(seperatorColor);
  }
}

.el-progress-circle {
  width: 40px;
  height: 40px;
  color: red;
}

.el-progress--circle ::v-deep .el-progress__text {
  @include font_color(homeStatusProgressTextColor);
}

::v-deep .el-table__body-wrapper {
  height: calc(100% - 40px);
  overflow-y: auto !important;
}

::v-deep.el-button--primary {
  border-radius: 0;
  // background-image: linear-gradient(to right, #00ffff, #00aff8);
  background-color: rgb(36, 157, 216);
   background:radial-gradient(ellipse ,#000,#1668ac);
  border: none;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 0 !important;
}

::v-deep .el-table__body-wrapper {
  -ms-overflow-style: none;
}

::v-deep .el-table__body-wrapper {
  overflow: -moz-scrollbars-none;
}

::v-deep .el-table::before {
  height: 0px !important;
}

::v-deep .el-table,
.el-table__expanded-cell {
  background: none !important;
}

::v-deep .el-table tr {
  background: none;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  background: none;
}

.red {
  color: #d1217f;
}

.blue {
  color: #007be5;
}

.pink {
  color: #ef4444;
}

.weight {
  font-weight: 700;
  font-size: 20px;
}

.orange {
  color: #f59e0b;
}

.bg-white {
  background-color: #fff;
}

.font-s {
  margin-top: 2px;
  margin-right: 5px;
  // color: #707070;
}

.state-flex {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding-left: 10px;
}

.flex {
  flex: 1;
}

.home {
  height: 100%;
  background-color: #fff;
  display: flex;
  gap: 10px;
  padding: 10px;
  @include background_color('homeBgColor');
}

.home-left {
  flex: 12;
  display: flex;
  gap: 10px;
  flex-direction: column;

  .sos {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: #fff;
    padding-left: 18px;
    border: 1px solid #ddd;

    .text {
      color: red;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 1px;
      margin-right: 10px;
    }

    .scool {
      flex: 1;
      overflow: hidden;
    }

    ul {
      display: flex;
      flex-direction: row;

      li {
        cursor: pointer;
        background-color: red;
        color: #fff;
        font-weight: 700;
        padding: 0 10px;
        margin-right: 10px;
      }
    }
  }

  .table {
    flex: 3;
    // height: 300px;
    display: flex;
    gap: 10px;
    overflow-y: auto;

    .fault {
      flex: 1;
      display: flex;
      height: 100%;
      @include background_color('faultBgColor');
      overflow: auto;
      //   border: 1px solid #ddd;
      @include border_color(panelBorderColor);

      .tips {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .round {
          width: 68px;
          height: 68px;
          font-size: 14px;
          font-weight: 700;
          margin: 0 auto;
          text-align: center;
          background-color: red;
          border-radius: 68px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .list {
        flex: 6;
        overflow-x: hidden;
      }
    }

    .uncivilized {
      flex: 1;
      background-color: #fff;
    }
  }

  .shadow-wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0px;
    width: 90%;
    height: 100%;
  }

  .vallage-list {
    flex: 9;
    // height: 100%;
    @include background_color(homeVillageListBgColor);
    border: 1px solid #ddd;
    @include border_color(panelBorderColor);
    overflow: hidden;

    .nav {
      height: 50px;
      @include background_color(homeNavBgColor);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .state {
        margin-top: 5px;
        margin-left: 5px;
        display: flex;
        height: 45px;
        line-height: 45px;

        .type {
          color: #fff;
          background-image: none;
          padding: 0 20px;
          cursor: pointer;
          border-radius: 5px 5px 0 0;
        }

        .active {
          background-color: #fff;
          color: #fff;
          // background-image: linear-gradient(to right, #00ffff, #00aff8);
          background-color: rgb(36, 157, 216);
           background:radial-gradient(ellipse ,#000,#1668ac);
        }
      }

      .search {
        display: flex;
        padding-right: 10px;

        .time-select {
          display: flex;
          justify-content: space-between;
          align-items: center;
          //   background-image: linear-gradient(to right, #00ffff, #00aff8);
          //   background-color: #2d8cf0;
          border: 1px solid #fff;
          border-radius: 6px;
          color: #fff;

          .time {
            padding: 8px 16px;
            cursor: pointer;
          }

          .week {
            border-radius: 6px 0 0 6px;
          }

          .year {
            border-radius: 0 6px 6px 0;
          }

          .mouth {
            border: 1px solid #fff;
            border-top: none;
            border-bottom: none;
          }

          .active {
            // background-color: #fff;
            color: #fff;
            // background-image: linear-gradient(to right, #00ffff, #00aff8);
            background-color: rgb(36, 157, 216);
            background:radial-gradient(ellipse ,#000,#1668ac)
          }
        }
      }
    }

    .vallage-content {
      height: calc(100% - 50px);
      @include background_color(homeVillageContentBgColor);
      @include font_color(homeVillageContentTextColor);
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;
      overflow: auto;

      .item {
        width: calc((100% - 30px) / 4);
        height: 120px;
        border: 1px solid #2d8cf0;
        // background-color: rgb(245, 243, 243);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px;

        .item-title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            width: 6px;
            height: 6px;
            background-color: #aaa;
            border-radius: 6px;
          }

          .vallage-name {
            font-size: 20px;
            text-shadow: 5px 2px 6px #aaa;
            // background-color: rgb(233, 225, 225);
            margin: 0 50px 0 10px;
            font-weight: 700;
          }

          .ele-num {
            margin-right: 20px;
            color: #007be5;
            font-weight: 700;
            font-size: 20px;
          }
        }

        .ele-state {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          .state {
            flex: 1;
            text-align: center;
          }

          .state-num {
            font-size: 16px;
            font-weight: 700;
          }

          .state-type {
            margin-top: 4px;
            padding: 2px;
            @include font_color(homeVillageContentTextColor);

            color: #000;
            font-size: 13px;
            font-weight: 700;
            // background-color: #edeef6;
          }
        }
      }

      .item:hover {
        border: 1px solid #2d8cf0;
        // background-color: #2d8cf0;
      }
    }

    .elevator-content {
      height: calc(100% - 50px);
      padding: 10px;
      overflow: auto;

      .ele-state {
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        display: flex;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          margin-right: 20px;

          p {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-right: 4px;
          }
        }
      }

      .elevator-list {
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;

        .item {
          width: calc((100% - 90px) / 10);
          height: 120px;
          @include background_color(homeElevatorItemBgColor);
          @include font_color(homeElevatorItemTextColor);
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
          font-size: 12px;

          img {
            width: 60px;
            height: 60px;
            margin: 6px 0;
          }
        }
      }
    }

    .map {
      height: calc(100% - 50px);
      padding: 10px;
      overflow: auto;
      @include background_color(homeMapBgColor);
      position: relative;
    }

    .drow {
      width: 300px;
      height: 96%;
      //   background: pink;
      background-color: #0d1d4d;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .vallage-content::after {
      content: '';
      flex: auto;
    }

    .vallage-content::-webkit-scrollbar {
      display: none;
    }

    .elevator-list::after {
      content: '';
      flex: auto;
    }

    .elevator-content::-webkit-scrollbar {
      display: none;
    }
  }
}
.hiu {
  background-color: pink;
}

.home-right {
  height: 100%;
  flex: 3;
  // background-color: red;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .num {
    margin-top: 10px;
    // padding: 10px;
    flex: 1;
    display: flex;

    @include border_color(panelBorderColor);
    gap: 10px;
    @include background_color(homeOverviewBgColor);
    background-color: #fff;

    .ele-num {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;

      .nor-text {
        @include font_color(homeOverviewTextColor);
      }

      .highlight-text {
        @include font_color(homeOverviewHighlightTextColor);
      }
    }
  }

  .state {
    flex: 4;

    .title {
      height: 50px;
      line-height: 50px;
      font-weight: 700;
      font-size: 24px;
      color: #000;
      @include font_color(homeStatusTitleTextColor);
    }

    .main-state {
      //   border: 1px solid #ddd;
      @include border_color(panelBorderColor);
      // height: calc(100% - 50px);
      border-bottom: none;
    }
  }

  .info {
    flex: 5;
    display: flex;
    flex-direction: column;

    .title {
      height: 50px;
      line-height: 50px;
      font-weight: 700;
      font-size: 24px;
      @include font_color(homeInfoTitleTextColor);
    }
  }

  .main-info {
    //   border: 1px solid #ddd;
    @include border_color(panelBorderColor);
    height: calc(100% - 50px);
    @include background_color(homeInfoBgColor);
    @include font_color(homeInfoTextColor);
    display: flex;
    flex-direction: column;

    .contemt {
      flex: 1;
      padding: 10px;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      div {
        display: flex;
        align-items: center;

        p {
          background-color: #2d8cf0;
          color: #fff;
          margin-right: 10px;
          width: 30px;
          height: 30px;
          line-height: 30px;
          border-radius: 30px;
          text-align: center;
        }
      }
    }

    .peo-info {
      margin-top: 10px;
      height: 40px;
      padding-left: 20px;
      background-color: #fff;

      i {
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}

#box1 {
  height: 12%;
}

#box2 {
  height: 50%;
}

#box3 {
  height: 38%;
}

#box4 {
  width: 50%;
  height: 100%;
}

#box5 {
  height: 70%;
}

.el-row {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.bg-purple-dark {
  background: #fff;
}

.bg-purple {
  @include background_color(homeBgPurpleBgColor);
  @include border_color(seperatorColor);
}

.bg-purple-light {
  background: #fff;
}

.grid-content {
  min-height: 70px;
}

.row-bg {
  // padding: 10px 0 0;
  background-color: #fff;
}

.svgClass {
  width: 30px;
  height: 30px;
  color: pink;
}

.svgClass:hover {
  color: #409eff;
}

.svg-icon_home_index {
  width: 2.5em;
  height: 2.5em;
  fill: currentColor;
  overflow: hidden;
}

video {
  width: 100%;
  height: 500px;
}

::v-deep video[data-v-087d42bb] {
  height: 500px;
}

.blink {
  display: inline-block;
  font-size: 24px;
  margin: 10px;
  background: linear-gradient(left, #f71605, #e0f513);
  background: -webkit-linear-gradient(left, #f71605, #e0f513);
  background: -o-linear-gradient(right, #f71605, #e0f513);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: scratchy 0.253s linear forwards infinite;
  /* 其它浏览器兼容性前缀 */
  -webkit-animation: scratchy 0.253s linear forwards infinite;
  -moz-animation: scratchy 0.253s linear forwards infinite;
  -ms-animation: scratchy 0.253s linear forwards infinite;
  -o-animation: scratchy 0.253s linear forwards infinite;
}

@keyframes scratchy {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 0 0;
  }

  26% {
    background-position: 20px -20px;
  }

  50% {
    background-position: 20px -20px;
  }

  51% {
    background-position: 40px -40px;
  }

  75% {
    background-position: 40px -40px;
  }

  76% {
    background-position: 60px -60px;
  }

  99% {
    background-position: 60px -60px;
  }

  100% {
    background-position: 0 0;
  }
}

/* 添加兼容性前缀 */
@-webkit-keyframes scratchy {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 0 0;
  }

  26% {
    background-position: 20px -20px;
  }

  50% {
    background-position: 20px -20px;
  }

  51% {
    background-position: 40px -40px;
  }

  75% {
    background-position: 40px -40px;
  }

  76% {
    background-position: 60px -60px;
  }

  99% {
    background-position: 60px -60px;
  }

  100% {
    background-position: 0 0;
  }
}

@-moz-keyframes scratchy {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 0 0;
  }

  26% {
    background-position: 20px -20px;
  }

  50% {
    background-position: 20px -20px;
  }

  51% {
    background-position: 40px -40px;
  }

  75% {
    background-position: 40px -40px;
  }

  76% {
    background-position: 60px -60px;
  }

  99% {
    background-position: 60px -60px;
  }

  100% {
    background-position: 0 0;
  }
}

@-ms-keyframes scratchy {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 0 0;
  }

  26% {
    background-position: 20px -20px;
  }

  50% {
    background-position: 20px -20px;
  }

  51% {
    background-position: 40px -40px;
  }

  75% {
    background-position: 40px -40px;
  }

  76% {
    background-position: 60px -60px;
  }

  99% {
    background-position: 60px -60px;
  }

  100% {
    background-position: 0 0;
  }
}

@-o-keyframes scratchy {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 0 0;
  }

  26% {
    background-position: 20px -20px;
  }

  50% {
    background-position: 20px -20px;
  }

  51% {
    background-position: 40px -40px;
  }

  75% {
    background-position: 40px -40px;
  }

  76% {
    background-position: 60px -60px;
  }

  99% {
    background-position: 60px -60px;
  }

  100% {
    background-position: 0 0;
  }
}

.pull-left {
  height: 45px;
  float: left;
  font-size: 16px;
}

.panel-title {
  padding-right: 16px;

  .tag-box {
    margin-top: -2px;
    margin-left: -2px;
  }
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: '.';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.pull-right {
  height: calc(100% - 45px);
  float: right !important;
  width: 100%;
}

.status {
  line-height: 45px;
  font-size: 24px;
}

.echart-box {
  width: 100px;
  height: 100px;
}
.offline {
  background-color: #ddd;
}
</style>