<template>
  <!-- <bm-overlay ref="customOverlay" class="sample offline"  pane="labelPane" @draw="draw"> -->
  <bm-overlay
    ref="customOverlay"
    :class="offline == 0 ? 'sample offline' : 'sample'"
    class="sample"
    pane="labelPane"
    @draw="draw"
  >
    <div v-if="offline == 0">
      <el-tooltip class="item" effect="light" :content="text" placement="top">
        <p style="font-size:14px;color:rgb(218, 214, 15,0)">{{ offline }}</p>
      </el-tooltip>
    </div>
    <div v-else @click="handleClick(villageId)">
      <el-tooltip class="item" effect="light" placement="top">
        <div style="color:#000" slot="content">{{text}} <br/> 梯:{{elevatorNum?elevatorNum:0}} 困:{{peopleTrappedCount?peopleTrappedCount:0}} <span style="position:relative;"><div class="line" ></div> 文</span>:{{electroMobileCount?electroMobileCount:0}}</div>
        <p style="font-size:20px;color:rgb(24, 222, 152,0)">{{ offline }}</p>
      </el-tooltip>

      <!-- <p style="font-weight: 600;font-size: 20px;">{{text}}</p> -->
      <!-- <p v-if="offline!=0" style="font-weight: 600;font-size: 20px;">梯:{{elevatorNum?elevatorNum:0}} 困:{{peopleTrappedCount?peopleTrappedCount:0}} 电:{{electroMobileCount?electroMobileCount:0}}</p> -->
    </div>
  </bm-overlay>
  <!--  -->
</template>

<script>
export default {
  props: [
    'text',
    'position',
    'active',
    'elevatorNum',
    'peopleTrappedCount',
    'electroMobileCount',
    'villageId',
    'offline',
  ],
  watch: {
    position: {
      handler() {
        this.$refs.customOverlay.reload()
      },
      deep: true,
    },
  },
  methods: {
    draw({ el, BMap, map }) {
      const { lng, lat } = this.position
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
      el.style.left = pixel.x - 60 + 'px'
      el.style.top = pixel.y - 20 + 'px'
    },
    handleClick(village) {
      this.$emit('openMapDrawer', village)
    },
  },
}
</script>

<style scoped>
.sample {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
/*  background-color: rgb(24, 222, 152);*/
   background:radial-gradient(circle, rgba(0,0,0,0.3),rgb(24, 222, 152)); 
  text-align: center;
  /* background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white;
  position: absolute;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  font-size: 24px;
  box-shadow: 0 0 10px rgb(56, 56, 56);
  /* padding-top: 6px; */
  z-index: 999;
}
p{
  /* background-color: yellow; */
  /* width: 100%;
  height: 100%; */
}

.sample::-webkit-scrollbar {
  display: none;
}
.sample.active {
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
}
.sample.offline {
  width: 25px;
  height: 25px;
  color: #fff;
  line-height: 25px;
  border-radius: 20px;
/*  background-color: rgb(218, 214, 15);*/
   background:radial-gradient(circle ,rgba(0,0,0,0.3),rgb(218, 214, 15)) 
  /* padding-top: 2px; */
  /* background-image: url('https://video.shmashine.com/bubble_gray.png'); */
  
}
.line{
  display: inline-block;
  width: 24px;
  height: 1px;
  background-color: #000;
  transform: rotate(45deg);
  position: absolute;
  top: 48%;
  left:-10% ;
}

/* .item {
  margin: 4px;
} */
</style>